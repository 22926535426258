import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ClickhouseService } from '../services/clickhouse.service';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppStateInterface } from '../../store/state/app.state';
import { eventIdSelector, uuidSelector } from '../../store/selectors/profile.selectors';
import { getSubscriptionInfo } from '../../store/actions/subscription.actions';
import { getBillingInfo } from '../../store/actions/billing.actions';
import { setUuid } from '../../store/actions/profile.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private uuid: string = '';

  private eventId: string = '';

  constructor(
    private router: Router,
    private authService: AuthService,
    private clickhouseService: ClickhouseService,
    private store: Store<AppStateInterface>,
  ) {
    combineLatest([this.store.select(uuidSelector), this.store.select(eventIdSelector)]).subscribe(
      ([uuid, eventId]) => {
        this.uuid = uuid;
        this.eventId = eventId;
      },
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (route.queryParams.auth || route.queryParams.token) {
      this.store.dispatch(setUuid({ uuid: route.queryParams.auth || route.queryParams.token }));
      return this.authService
        .getProfileData(route.queryParams.auth || route.queryParams.token)
        .pipe(
          map((response: any) => {
            if (response.status === 'success') {
              this.clickhouseService.storeEvent('login_deeplink').subscribe(() => {});
              this.store.dispatch(getBillingInfo());
            } else {
              this.router.navigate(['/auth/login']);
            }
            return response.status === 'success';
          }),
        );
    } else if (this.uuid && !this.eventId) {
      return this.authService.getProfileData().pipe(
        map((response: any) => {
          if (response.status === 'success') {
            this.store.dispatch(getBillingInfo());
            this.store.dispatch(getSubscriptionInfo());
          } else {
            this.router.navigate(['/auth/login']);
          }
          return response.status === 'success';
        }),
      );
    } else if (this.uuid && this.eventId) {
      return true;
    } else {
      this.router.navigate(['/auth/login']);
      return false;
    }
  }
}
