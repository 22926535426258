import { BillingStoreInterface } from '../models/billing-store.interface';

export const initialBillingState: BillingStoreInterface = {
  autoCharge: false,
  hasSubscription: true,
  isVip: false,
  userBalance: 0,
  subscriptionType: 'onetime', // ['onetime', 'regular']
  coinsPerDay: 0,
  nextDailyEdge: 0,
  isTrial: false,
  processingUuid: '',
  isSubscriptionCanceled: false,
};
