import { createReducer, on } from '@ngrx/store';
import { initialBillingState } from '../state/billing.state';
import { setAutoCharge, setBillingInfo, setSubscriptionStatus, setVipStatus } from '../actions/billing.actions';

export const billingReducer = createReducer(
  initialBillingState,
  on(setBillingInfo, (_state, newState) => ({
    ...newState,
  })),
  on(setAutoCharge, (state, newState) => ({
    ...state,
    autoCharge: newState.autoCharge,
  })),
  on(setVipStatus, (state, newState) => ({
    ...state,
    isVip: newState.status,
  })),
  on(setSubscriptionStatus, (state, newState) => ({
    ...state,
    hasSubscription: newState.status,
  })),
);
