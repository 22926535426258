<div class="w-100 pt-10 position-relative pb-7">
  <button
    *ngIf="canClose"
    type="button"
    class="modal-close btn-close"
    (click)="closeModal()"
  >
  </button>
  <div class="row justify-content-center">
    <div class="col-11">
      <p
        class="text-center mb-3"
        *ngIf="subtitle">
        {{subtitle}}
      </p>
      <h2
        class="text-center mb-3"
        *ngIf="title">
        {{ title }}
      </h2>
      <p
        class="text-center"
        *ngIf="content"
      >
        {{ content }}
      </p>
    </div>
  </div>
  <div
    class="row justify-content-center mt-5"
    *ngIf="buttons">
    <div class="col-11 col-md-9">
      <button
        class="btn w-100 mb-3"
        *ngFor="let button of buttons; trackBy: trackByFn"
        [class.btn-outline]="button.outline"
        [class.app-btn-yellow]="button.type === 'yellow'"
        [class.app-btn-black]="button.type === 'black'"
        [class.app-btn-gray]="button.type === 'gray'"
        (click)="buttonCallback(button.callback)"
      >
        {{ button.title }}
      </button>
    </div>
  </div>
</div>
